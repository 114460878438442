import React, {Component} from 'react'
import Img from "gatsby-image"
import {Link} from 'gatsby'
import './card.scss'

export default class Card extends Component {
    render() {

        const node = this.props.node;
        const title = node.frontmatter.title || node.fields.slug;

        let featuredImage;
        if (node.frontmatter.featuredImage) {
            featuredImage = <Img className='featured-image'
                                 fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                                 title={title}/>
        }
        return (
            <article
                // data-sal="slide-up"
                // data-sal-delay="100"
                // data-sal-easing="ease"
                className='card'
                key={node.fields.slug}>
                <Link className='post-link' to={node.fields.slug}>
                    {featuredImage}
                    <header className='card-header'>
                        <h2 className='post-title'>{title}</h2>
                    </header>
                </Link>
            </article>
        )
    }
}
