import React from 'react';
import {graphql} from 'gatsby'
import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO'
import {Grid} from 'semantic-ui-react'
import Card from "../components/Card/Card";
import '../styles/global.scss'

class BlogListTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.emptyQuery = ""
        this.state = {
            filteredData: [],
            query: this.emptyQuery
        }
    }

    render() {
        const {data} = this.props;
        const siteTitle = data.site.siteMetadata.title;
        const allPosts = data.allMdx.edges;

        if (typeof window !== `undefined`) {
            window.handleSearchPostInputChange = event => {
                const query = event.target.value

                const allPosts = data.allMdx.edges || []

                const filteredData = allPosts.filter(post => {
                    const {title, category, tags} = post.node.frontmatter
                    return (
                        title.toLowerCase().includes(query.toLowerCase()) ||
                        category.toLowerCase().includes(query.toLowerCase()) ||
                        tags
                            .join("") // convert tags from an array to string
                            .toLowerCase()
                            .includes(query.toLowerCase())
                    )
                })
                this.setState({
                    query, // with current query string from the `Input` event
                    filteredData, // with filtered data from posts.filter(post => (//filteredData)) above
                })
            }
        }

        const {filteredData, query} = this.state
        const hasSearchResults = filteredData && query !== this.emptyQuery
        const posts = hasSearchResults ? filteredData : allPosts

        return (
            <Layout location={this.props.location}
                    title={siteTitle}>
                <SEO title="All posts"
                     keywords={[`gatsby`, `blog`, `patisserie`, `azd`]}/>
                <Grid doubling stackable columns={3}>
                    {posts.map(({node}) => {
                        return (
                            <Grid.Column>
                                <Card node={node}/>
                            </Grid.Column>
                        )
                    })}
                </Grid>
            </Layout>
        )
    }
}

export default BlogListTemplate

export const blogListQuery = graphql`
    query blogListQuery {
        site {
            siteMetadata {
                title
            }
        }
        allMdx(
            filter: { frontmatter: { published: { eq: true } } }
            sort: { fields: [frontmatter___date], order: DESC }
        ) {
            edges {
                node {
                    excerpt
                    fields {
                        slug
                    }
                    frontmatter {
                        published
                        date(formatString: "DD MMMM, YYYY")
                        title
                        category
                        tags
                        featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 400) {
                                    ...GatsbyImageSharpFluid_tracedSVG
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
